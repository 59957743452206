<template>
    <div class="text-center" style="margin-top:40vh">
      <h2>404</h2>
      <p>Nie znaleziono</p>
      <v-btn color="primary" depressed @click.native="$router.push({ path: '/' })">Powrót</v-btn>
  </div>
</template>
<script>
export default {
  name: 'Notfound'
}
</script>
